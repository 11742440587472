import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Col, Container, Row } from "react-awesome-styled-grid"
import styled from "styled-components"
import Layout from "../../components/Layout"
import { Heading2 } from "../../components/Heading"
import Block from "../../components/Block"
import BackgroundImage from "gatsby-background-image"
import { Hero, HeroContent, HeroHeading } from "../../components/Hero"

const TableWrapper = styled.div`
  margin-top: 40px;
  overflow-x: auto;
`

const Table = styled.table`
  font-size: 1.1rem;
  border: none;
  outline: none;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0 auto;
  width: 1190px;

  th {
    font-size: 1.2rem;
  }

  th,
  td {
    padding: 15px;
    background-color: #fff;
  }

  tr:nth-child(even) > th,
  tr:nth-child(even) > td {
    background-color: #d9ebfc;
  }

  td {
    width: auto;
    vertical-align: top;
  }

  th:first-child,
  td:first-child {
    width: 150px;
    max-width: 30vw;
    position: sticky;
    left: 0px;
  }

  ol {
    margin: 0;

    li:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`

const Background = ({ className, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "stock/oil-water.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <BackgroundImage
      className={className}
      fluid={data.image.childImageSharp.fluid}
      style={{ height: "100%" }}
    >
      {children}
    </BackgroundImage>
  )
}

const ProduktRatgeber = () => {
  return (
    <Layout title="CBD Produkte Vor- und Nachteile" mt0 light>
      <Hero heightVh={30}>
        <Background>
          <HeroContent align="flex-end">
            <Container>
              <Row>
                <Col>
                  <HeroHeading>
                    Vorteile und Nachteile von CBD Öl und CBD Fruchtgummis
                  </HeroHeading>
                </Col>
              </Row>
            </Container>
          </HeroContent>
        </Background>
      </Hero>
      <Container>
        <Row>
          <Col>
            <TableWrapper>
              <Table cellSpacing={0} cellPadding={0}>
                <thead>
                  <tr>
                    <th aria-label="Kategorie"></th>
                    <th>CBD Öl</th>
                    <th>CBD Fruchtgummis</th>
                  </tr>
                </thead>
                <tr>
                  <th>Vorteile</th>
                  <td>
                    <ol>
                      <li>CBD Wirkung nach 15 Minuten möglich</li>
                      <li>
                        Ein Fläschchen CBD Öl reicht für ca. 2,5 Monate (3
                        Tropfen/ Tag)
                      </li>
                      <li>
                        Viele Anbieter von CBD Öl mit deutlichen Unterschieden
                      </li>
                    </ol>
                  </td>
                  <td>
                    <ol>
                      <li>CBD Wirkung bis zu 8 Stunden</li>
                      <li>
                        Eine Packung CBD reicht für 2 Monate
                        <br />
                        (1 Stück / Tag)
                      </li>
                      <li>Bitterer CBD Geschmack i.d.R. nicht zu schmecken</li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <th>Nachteile</th>
                  <td>
                    <ol>
                      <li>CBD Wirkung bis zu 6 Stunden</li>
                      <li>CBD Öl hat einen ungewohnten Geschmack</li>
                      <li>
                        CBD Öl kann teuer sein durch falsches
                        Preis-Leistungs-Verhältnis
                      </li>
                    </ol>
                  </td>
                  <td>
                    <ol>
                      <li>Wenige Anbieter von CBD Fruchtgummis</li>
                      <li>CBD Wirkung erst nach ca. 1-2 Stunden</li>
                      <li>CBD Fruchtgummis enthalten Zucker</li>
                    </ol>
                  </td>
                </tr>
              </Table>
            </TableWrapper>
          </Col>
        </Row>
        <Block>
          <Row>
            <Col>
              <Heading2>Gut informiert vor dem Kauf von CBD Produkten</Heading2>
              <p>
                CBD hilft als natürlicher Wirkstoff der Hanfpflanze bei{" "}
                <strong>Angstzuständen, Unwohlsein und Stressempfinden</strong>,
                das haben{" "}
                <a
                  href="https://royalsocietypublishing.org/doi/10.1098/rstb.2011.0389"
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  Campos et al. 2012
                </a>{" "}
                in ihrer Studie bereits nachgewiesen. Heute gibt es viele
                verschiedene CBD Produkte wie zum Beispiel das{" "}
                <Link to="/vergleiche/cbd-oel/">CBD Öl</Link> oder die{" "}
                <Link to="/vergleiche/cbd-fruchtgummis/">CBD Fruchtgummis</Link>
                , aber worin unterscheiden sich die CBD Produkte? Wir zeigen die
                Unterschiede auf und stellen die wesentlichen Vorteile und
                Nachteile des CBD Öls und der CBD Fruchtgummis gegenüber.{" "}
                <strong>
                  Dadurch können Sie eine bessere Wahl für Ihr präferiertes CBD
                  Produkt treffen
                </strong>
                .
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2>Vorteile CBD Öl</Heading2>
              <p>
                Durch die <strong>sublinguale Einnahme</strong> von CBD Öl
                gelangt das Cannabidiol direkt über eine sich unter der Zunge
                befindliche große Vene in den Blutkreislauf und muss nicht erst
                den Verdauungstrakt passieren. Dadurch können{" "}
                <strong>erste Wirkungen bereits nach 15 Minuten</strong> möglich
                sein. Um eine schnelle Wirkung zu erzielen, ist der CBD Gehalt
                pro Tropfen ausschlaggebend. Es gibt{" "}
                <Link to="/vergleiche/cbd-oel/">CBD Öl Testsieger</Link>, die
                teilweise eine doppelte CBD Wirkung haben als gängige Präparate.
              </p>

              <p>
                Auch wenn CBD Öl auf den ersten Blick nicht sehr billig
                erscheinen mag,{" "}
                <strong>
                  hält ein Fläschchen bei täglichem Konsum von 3 Tropfen für
                  ungefähr 2-3 Monate
                </strong>
                . Setzt man den Preis in Relation zu dieser doch recht langen
                Zeitspanne, so wirken die Preise angemessen.
              </p>

              <p>
                Da CBD am häufigsten als Öl konsumiert wird, gibt es
                dementsprechend auch{" "}
                <strong>viele verschiedene Anbieter von Hanföl</strong>. Dadurch
                hat man als Konsument eine große Auswahl an verschiedenen
                Produkten mit{" "}
                <strong>teilweise deutlichen Unterschieden</strong>, zum
                Beispiel in Geschmack, Qualität und Herkunft. Damit Sie bei der
                riesigen Auswahl an Anbietern das für Sie am besten geeignete
                Produkt auswählen können, haben wir in unserem{" "}
                <Link to="/vergleiche/cbd-oel/">großen CBD Öl Vergleich</Link>{" "}
                viele verschiedene Produkte getestet und miteinander verglichen.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2>Nachteile CBD Öl</Heading2>
              <p>
                Da wir hohen Stellenwert auf Transparenz setzen, möchten wir
                selbstverständlich auch objektiv auf die Nachteile von CBD Öl
                eingehen. Zunächst sei gesagt, dass die Wirkung des Cannabidiol{" "}
                <strong>nur bis zu 6 Stunden lang anhält</strong>, wenn es als
                Öl konsumiert wird. Mit anderen Konsumformen, wie zum Beispiel
                CBD Fruchtgummis, kann man unter Umständen eine längere
                Wirkungsdauer erzielen. Dadurch, dass das Öl recht einfach zu
                konsumieren ist, ist es aber auch kein Problem, einfach mehrere
                Tropfen über den Tag verteilt zu sich zu nehmen.
              </p>
              <p>
                Außerdem hat CBD Öl für viele einen{" "}
                <strong>
                  ungewohnten, manchmal vielleicht sogar unangenehmen Geschmack
                </strong>
                . Je nach Anbieter kann der Geschmack verschiedene Noten
                annehmen und unterschiedlich intensiv sein. Im Allgemeinen wird
                reines Hanföl als <strong>erdig, wurzelig und bitter</strong>{" "}
                beschrieben. Wer empfindliche Geschmacksnerven hat, sollte also
                besser auf ein mildes Produkt oder auf aromatisierte Hanföle
                zurückgreifen, welche den Eigengeschmack des Cannabidiol
                überdecken. In unserem{" "}
                <Link to="/vergleiche/cbd-oel/">ausführlichen CBD Öl Test</Link>{" "}
                bewerten und vergleichen wir unter anderem auch den Geschmack
                der verschiedenen Produkte.
              </p>
              <p>
                Ein dritter Nachteil ist der oft{" "}
                <strong>recht teure Preis von CBD Öl</strong>. Bei der Vielzahl
                an Produkten gibt es leider auch viele Anbieter, bei denen das{" "}
                <strong>Preis-Leistungs-Verhältnis unstimmig</strong> ist. Umso
                wichtiger ist es, sich vor dem Kauf ausgiebig über verschiedene
                Hanföle zu informieren, um ein qualitativ hochwertiges Produkt
                von einem seriösen Anbieter zu einem fairen Preis zu kaufen. Als
                Informationsquelle können Sie dafür auch unseren{" "}
                <Link to="/vergleiche/cbd-oel/">CBD Öl Preisvergleich</Link>{" "}
                heranziehen. Dort vergleichen wir 8 große Anbieter von CBD Öl
                miteinander und helfen Ihnen dabei, eine qualifizierte
                Kaufentscheidung zu treffen.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2>Vorteile CBD Fruchtgummis</Heading2>
              <p>
                Eines der Hauptvorteile der CBD Fruchtgummis ist die längere
                Wirkung des CBDs im Körper. Das Cannabidiol kann{" "}
                <strong>bis zu 8 Stunden wirken</strong>, wenn man 2-3 CBD
                Fruchtgummis pro Tag isst. Mehr als drei CBD Fruchtgummis pro
                Tag werden nicht empfohlen. Der Wirkstoffgehalt unterscheidet
                sich nach Anbieter und kann 10 mg oder sogar 12 mg CBD pro
                Gummibärchen enthalten.{" "}
                <Link to="/vergleiche/cbd-fruchtgummis/">Hier</Link> können Sie
                die vier großen Anbieter miteinander vergleichen. Wenn Sie
                langsam einsteigen möchten und sich dazu entscheiden, ein CBD
                Fruchtgummi pro Tag zu essen,{" "}
                <strong>reicht eine Packung bis zu zwei Monate aus</strong>. In
                unserem{" "}
                <Link to="/vergleiche/cbd-fruchtgummis/">Vergleich</Link>{" "}
                enthielten alle Packungen 60 CBD Fruchtgummis.
              </p>
              <p>
                Ein weiterer Vorteil von CBD Fruchtgummis ist, dass sie lecker
                schmecken und der klassisch{" "}
                <strong>
                  bittere Geschmack von CBD dadurch i.d.R. nicht zu schmecken
                </strong>{" "}
                ist.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2>Nachteile CBD Fruchtgummis</Heading2>
              <p>
                Es gibt <strong>wenige Anbieter von CBD Fruchtgummis</strong> im
                Vergleich zu Verkäufern von CBD Öl. Das liegt wahrscheinlich
                daran, dass das CBD Öl deutlich bekannter ist und es deswegen
                mehr Verkäufer in dem Bereich gibt. Aus diesem Grund gibt es bei
                den CBD Fruchtgummis nicht die große freie Wahl. In unserem CBD
                Test haben wir die{" "}
                <Link to="/vergleiche/cbd-fruchtgummis/">
                  vier Hauptanbieter
                </Link>{" "}
                von CBD Fruchtgummis in Deutschland und Österreich verglichen.
              </p>
              <p>
                Ein weiterer Nachteil von den CBD Fruchtgummis ist, dass die{" "}
                <strong>Wirkung des CBDs im Organismus nach 1-2 Stunden</strong>{" "}
                und somit etwas langsamer eintritt. Sollten Sie also CBD vor dem
                Schlafengehen einnehmen, um besser einschlafen zu können,
                empfehlen wir Ihnen, die CBD Fruchtgummis ca. 2 Stunden vor dem
                Schlafengehen einzunehmen.
              </p>
              <p>
                Ein weiterer Nachteil, der mit den CBD Fruchtgummis einhergeht,
                ist, dass die Gummibärchen, wie viele andere Süßigkeiten auch,{" "}
                <strong>mit Zucker hergestellt</strong> werden. Bei einem
                Verzehr von höchstens 3 Gummibärchen pro Tag sollte das aber
                kein großes Problem darstellen.
              </p>
            </Col>
          </Row>
        </Block>
      </Container>
    </Layout>
  )
}

export default ProduktRatgeber
